<template>
  <div>
    <a v-if="isLoggedIn" id="button_hide_menu" @click="toggleFullScreen()"
       class="Theme_C1_Headline fullscreen">
      <i v-if="!isFullScreen" class="fa fa-arrow-circle-o-left"/>
      <i v-if="isFullScreen" class="fa fa-arrow-circle-o-right"/>
    </a>
    <ul id="grid">
      <admin-menu/>

      <li id="contentMain">
        <div id="menuTop" class="materialShadow">
          <div class="top_string Theme_C1_Headline">{{ header }}
            <dashboard-date-range v-if="currentPage===Views.Dashboard"/>
            <gallery-top-bar v-if="currentPage===Views.Gallery"/>
          </div>
          <ul>
            <li>&nbsp;</li>
            <li>&nbsp;<!--<i class="fa fa-bell"></i>--></li>
            <li>
              <gallery-topbar-right v-if="currentPage===Views.Gallery"/>
            </li>
          </ul>

        </div>
        <div id="contentArea">
          <router-view/>
        </div>

      </li>
    </ul>

    <template v-if="isLoggedIn">
      <div id="optionsContainer">
        <dashboard-options ref="dashboardOptions"/>
      </div>
      <popups/>
      <dyn-popup2/>
      <zoom-container/>
    </template>
    <div id="uploadContainer"/>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import { isEmpty } from 'lodash';
  import translate from './Mixins/Translate';
  import Views from '../enums/views';
  import AdminMenu from './AdminMenu';

  const Popups = defineAsyncComponent(() => import('./Popup/Popups'));
  const ZoomContainer = defineAsyncComponent(() => import('./ZoomContainer'));
  const DashboardOptions = defineAsyncComponent(() => import('./Dashboard/DashboardOptions.vue'));
  const DashboardDateRange = defineAsyncComponent(() => import('./Dashboard/DashboardDateRange.vue'));
  const GalleryTopBar = defineAsyncComponent(() => import('./Gallery/GalleryTopbar'));
  const DynPopup2 = defineAsyncComponent(() => import('./DataList/DataEditorPopup'));
  const GalleryTopbarRight = defineAsyncComponent(() => import('./Gallery/GalleryTopbarRight'));

  export default {
    name: 'admin',
    mixins: [translate],
    components: {
      DynPopup2,
      'zoom-container': ZoomContainer,
      popups: Popups,
      'admin-menu': AdminMenu,
      'dashboard-options': DashboardOptions,
      'dashboard-date-range': DashboardDateRange,
      'gallery-top-bar': GalleryTopBar,
      'gallery-topbar-right': GalleryTopbarRight,
    },
    data() { return { Views }; },
    computed: {
      header() {
        switch (this.$store.state.currentPage) {
          case Views.Dashboard:
            return this.x('dashboard');
          case Views.Districts:
            return `${this.x('dataList')} | ${this.x('districts')}`;
          case Views.Channels:
            return `${this.x('dataList')} | ${this.x('channels')}`;
          case Views.Chains:
            return `${this.x('dataList')} | ${this.x('chains')}`;
          case Views.Customers:
            return `${this.x('dataList')} | ${this.x('customers')}`;
          case Views.Products:
            return `${this.x('dataList')} | ${this.x('products')}`;
          case Views.Campaigns:
            return `${this.x('dataList')} | ${this.x('campaigns')}`;
          case Views.Users:
            return `${this.x('dataList')} | ${this.x('users')}`;
          case Views.Announcements:
            return `${this.x('dataList')} | ${this.x('announcements')}`;
          case Views.AdminFeed:
            return this.x('feedHeadline');
          case Views.Gallery:
            return this.x('gallery');
          case Views.SystemSettings:
            return `${this.x('settings')} | ${this.x('system')}`;
          case Views.InstallHelp:
            return this.x('myInstallHelp');
          default: return '';
        }
      },
      isLoggedIn() {
        return !isEmpty(this.user);
      },
      ...mapState(['user', 'isFullScreen', 'currentPage']),
    },
    watch: {
      isFullScreen(newValue) {
        if (newValue === true) {
          $('body').addClass('fullscreen');
        } else {
          $('body').removeClass('fullscreen');
        }
      },
    },
    async mounted() {
      window.addEventListener('resize', (e) => {
        const size = {
          width: e.target.innerWidth,
          height: e.target.innerHeight,
        };
        this.$store.commit('WindowStore/setWindowSize', size);
      });
    },
    methods: {
      toggleFullScreen() {
        this.$store.commit('setFullScreen', !this.isFullScreen);
      },
    },
  };
</script>
