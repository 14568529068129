<template>
  <li id="contentLeft">
    <div id="menuLeft" class="scheme_menu_background Theme_C1_BG">
      <br><br>

      <!--suppress RequiredAttributes -->
      <img :src="'api/v1/logo.png?v='+config.publicProperties.MobileWebAppVer" class="logo"/>

      <dl>
        <dt class="Theme_C1_Headline">{{x('home')}}</dt>
        <dd>
          <admin-menu-item :link="Views.Dashboard" icon-name="fa-home" @click="showPage">
            {{x('overview')}}
          </admin-menu-item>
        </dd>

        <dt class="Theme_C1_Headline">{{x('feed')}}</dt>
        <dd>
          <admin-menu-item :link="Views.AdminFeed" icon-name="fa-bar-chart-o" @click="showPage">
            {{x('startFeed')}}
          </admin-menu-item>
        </dd>

        <dt class="Theme_C1_Headline">{{x('gallery')}}</dt>
        <dd>
          <admin-menu-item :link="Views.Gallery" icon-name="fa-bar-chart-o" @click="showPage">
            {{x('showGallery')}}
          </admin-menu-item>
          <gallery-filter v-show="currentPage===Views.Gallery && !collapseGalleryMenu"/>
        </dd>

        <dt class="Theme_C1_Headline">{{x('dataLists')}}</dt>

        <admin-menu-item :link="Views.Districts" icon-name="fa-map-marker" @click="showPage">
          {{x('districts')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.Channels" icon-name="fa-map-marker" @click="showPage">
          {{x('channels')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.Chains" icon-name="fa-industry" @click="showPage">
          {{x('chains')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.Customers" icon-name="fa-home" @click="showPage">
          {{x('customers')}}
        </admin-menu-item>
        <admin-menu-item v-if="areProductsVisibleInMenu"
                         :link="Views.Products" icon-name="fa-star" @click="showPage">
          {{x('products')}}
        </admin-menu-item>
        <admin-menu-item v-if="areCampaignsVisibleInMenu"
                         :link="Views.Campaigns" icon-name="fa-flag" @click="showPage">
          {{x('campaigns')}}
        </admin-menu-item>
        <admin-menu-item v-if="areVisitPlansEnabled"
                         :link="Views.VisitPlans" icon-name="fa-truck" @click="showPage">
          {{x('visitPlans')}}
        </admin-menu-item>

        <dt class="Theme_C1_Headline">{{x('settings')}}</dt>

        <admin-menu-item :link="Views.Users" icon-name="fa-users" @click="showPage">
          {{x('users')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.Announcements" icon-name="fa-bullhorn" @click="showPage">
          {{x('menuAnnouncementsAdmin')}}
        </admin-menu-item>
        <admin-menu-item v-if="areImportFilesEnabled"
          :link="Views.ImportFiles" icon-name="fa-bullhorn" @click="showPage">
          {{x('importFiles')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.InstallHelp" icon-name="fa-question" @click="showPage">
          {{x('myInstallHelp')}}
        </admin-menu-item>
        <admin-menu-item :link="Views.SystemSettings" icon-name="fa-cog" @click="showPage">
          {{x('system')}}
        </admin-menu-item>
        <dd>
          <a @click="logout()" class="Theme_C1_TXT">
            <icon name="fa-sign-out Theme_C1_TXT"/>
            {{x('logOut')}}
            <span v-show="logoutConfirm" class="log-out confirm lsc-rounded-10 ThemeConfirmLogout">
              <icon name="fa-chevron-circle-left"/>{{x('confirm')}}
            </span>
          </a>
        </dd>
      </dl>
      <div class="lsc-clear"></div>
      <br>
      <!--suppress RequiredAttributes -->
      <img :src="'static/assets/gfx/'+config.publicProperties.PosmoLogoMenuPage" class="menu_logo">
    </div>
  </li>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import Views from '@/enums/views';
  import DataTypes from '@/enums/data-types';
  import Logout from '@/classes/logout';
  import translate from './Mixins/Translate';
  import AdminMenuItem from './AdminMenuItem';
  import GalleryFilter from './Gallery/GalleryFilter';

  export default {
    name: 'admin-menu',
    components: {
      'admin-menu-item': AdminMenuItem,
      'gallery-filter': GalleryFilter,
    },
    mixins: [translate],
    data() {
      return {
        Views,
        logoutConfirm: false,
        confirmTimer: null,
        collapseGalleryMenu: false,
      };
    },
    computed: {
      ...mapState(['config', 'user', 'currentPage']),
      ...mapGetters('FeatureToggleStore', [
        'areImportFilesEnabled',
        'areCampaignsVisibleInMenu',
        'areProductsVisibleInMenu',
        'areVisitPlansEnabled',
        'areImportFilesEnabled',
      ]),
    },
    watch: {
      currentPage(newValue) {
        this.routeToPage(newValue);
      },
    },
    mounted() {
      this.routeToPage(this.currentPage);
    },
    methods: {
      showPage(pageName) {
        if (pageName === Views.Gallery && this.currentPage === Views.Gallery) {
          this.collapseGalleryMenu = !this.collapseGalleryMenu;
          return;
        }

        this.collapseGalleryMenu = false;

        this.$store.commit('setCurrentPage', pageName);
      },
      async logout() {
        console.log('Logout');
        if (this.logoutConfirm) {
          this.confirmTimer = null;
          this.routeToPage(Views.Blank);
          await this.$nextTick();
          await Logout();
        } else {
          this.logoutConfirm = true;
          this.confirmTimer = setTimeout(() => {
            this.logoutConfirm = false;
          }, 2000);
        }
      },
      routeToPage(pageName) {
        let route;
        switch (pageName) {
          case Views.Dashboard: route = `/admin/${pageName}`; break;
          case Views.Districts: route = `/admin/datalist/${pageName}/createNewDistrict`; break;
          case Views.Channels: route = `/admin/datalist/${DataTypes.channels}/createNewChannel`; break;
          case Views.Chains: route = `/admin/datalist/${DataTypes.chains}/createNewChain`; break;
          case Views.Customers: route = `/admin/datalist/${DataTypes.customers}/createNewCustomer`; break;
          case Views.Products: route = `/admin/datalist/${DataTypes.products}/createNewProduct`; break;
          case Views.Campaigns: route = `/admin/datalist/${DataTypes.campaigns}/createNewCampaign`; break;
          case Views.Users: route = `/admin/datalist/${pageName}/createNewUser`; break;
          case Views.Announcements: route = `/admin/datalist/${pageName}/createNewAnnouncement`; break;
          case Views.AdminFeed: route = '/admin/AdminFeed'; break;
          case Views.Gallery: route = '/admin/Gallery'; break;
          case Views.SystemSettings: route = '/admin/SystemSettings'; break;
          case Views.AdminLogin: route = '/AdminLogin'; break;
          case Views.VisitPlans: route = `/admin/datalist/${pageName}/createNewVisitPlan`; break;
          case Views.ImportFiles: route = `/admin/datalist/${pageName}/importNewFile`; break;
          case Views.InstallHelp: {
            const component = defineAsyncComponent(() => import('./UserProfile/InstallHelp'));
            this.$store.commit('pushPopup', {
              component,
              title: this.x('installHelp'),
              direction: 'left',
            });
            return;
          }
          case Views.Blank: route = '/blank'; break;
          default: return;
        }
        if (this.$router.currentRoute.path === route) return;
        this.$router.push(route);
      },
    },
  };
</script>

<style scoped>
  a {
    cursor: hand;
  }
</style>
