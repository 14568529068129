<template>
  <dd>
    <a @click="linkClicked()" class="Theme_C1_TXT">
      <icon v-if="iconName !== undefined" :name="'fa Theme_C1_TXT ' + iconName"/>
      <slot>Menu item</slot>
    </a>
  </dd>
</template>

<script>
  import translate from './Mixins/Translate';

  export default {
    name: 'admin-menu-item',
    props: {
      link: {
        required: true,
        type: String,
      },
      iconName: {
        // The name of a font-awesome icon, e.g. "fa-cog".
        required: false,
        type: String,
      },
    },
    emits: ['click'],
    mixins: [translate],
    methods: {
      linkClicked() {
        this.$emit('click', this.link);
      },
    },
  };
</script>

<style scoped>
  a {
    cursor: pointer;
  }
</style>
