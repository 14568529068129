<template>
  <div class="gallery sortbox">
    <list-filter-item type="districts" :item-renderer="itemRenderer"
                      v-model="filters.districts" :show-inactive-toggle="true"/>
    <list-filter-item type="channels" :item-renderer="itemRenderer"
                      v-model="filters.channels" :show-inactive-toggle="true"/>
    <list-filter-item type="customers" :item-renderer="customerRenderer"
                      v-model="filters.customers"
                      :subset="filters.users" :show-inactive-toggle="true"/>
    <list-filter-item v-if="areProductsVisibleInMenu"
                      type="products" :item-renderer="itemRenderer"
                      v-model="filters.products" :show-inactive-toggle="true"/>
    <list-filter-item v-if="areCampaignsVisibleInMenu"
                      type="campaigns" :item-renderer="itemRenderer"
                      v-model="filters.campaigns"
                      :custom-active-filter="activeCampaignFilter"
                      :show-inactive-toggle="true"/>
    <list-filter-item type="chains" :item-renderer="chainRenderer"
                      v-model="filters.chains"/>
    <list-filter-item type="users" :item-renderer="userRenderer"
                      v-model="filters.users" :show-inactive-toggle="true"
                      :subset="filters.districts"/>
    <list-filter-item v-if="areSurveysEnabled"
                      type="surveys" :item-renderer="itemRenderer"
                      manual-label="survey"
                      v-model="filters.surveys"/>
    <list-filter-item v-if="isVisitScoreEnabled"
                      type="score" :item-renderer="itemRenderer"
                      v-model="filters.scores"
                      :manual-data-source="scores"/>
    <list-filter-item v-if="areVisitPlansEnabled"
                      type="visit-plans" :item-renderer="itemRenderer"
                      v-model="filters.visitPlans" manual-label="visitPlans"/>
    <list-filter-item v-if="isUserRoleFilterEnabled"
                      type="userRoles" :item-renderer="itemRenderer"
                      v-model="filters.userRoles"/>
    <list-filter-item v-if="isReactionsFilterEnabled && reactionsList.length > 1"
                      type="reactions" :item-renderer="itemRenderer"
                      v-model="filters.reactions"
                      :manual-data-source="reactionsList"
                      manual-label="reactionType"/>

    <div class="filter_time">
      <b v-if="areBookmarksEnabled" class="Theme_C1_Headline">
        {{ x('onlyBookmarks')}}
        <input class="favorite-checkbox" type="checkbox" name="only-favorites"
               v-model="filters.bookmarksOnly">

      </b>
      <b class="Theme_C1_Headline">{{ x('dateDashFrom') }}
        <input type="text" name="date-from" placeholder="DD-MM-YYYY"
               class="Theme_C1_TXT date-picker" style="display:inline-block;"
               ref="dateFrom"
               data-dateformat="DD-MM-YYYY" data-headerformat="MMM YYYY" readonly>
      </b>
      <b class="Theme_C1_Headline">{{ x('dateDashTo') }}
        <input type="text" name="date-to" placeholder="DD-MM-YYYY"
               class="Theme_C1_TXT date-picker" style="display:inline-block;"
               ref="dateTo"
               data-dateformat="DD-MM-YYYY" data-headerformat="MMM YYYY" readonly>
      </b>
    </div>

    <br>
    <button class="lsc-button lsc-rounded Theme_C3_BG Theme_C3_TXT Theme_C3_Border"
            @click="doQuery()"><i class="fa fa-filter"></i>{{ x('updateFilter') }}
    </button>
    <button class="lsc-button lsc-rounded Theme_C4_BG Theme_C4_TXT Theme_C4_Border "
            @click="resetFilter()"><i class="fa fa-trash-o"></i>{{ x('resetFilter') }}
    </button>

  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import { httpPost } from '@/classes/httpHelper';
  import {
    itemRenderer, chainRenderer, userRenderer, customerRenderer,
  } from '@/classes/item-renderers';
  import translate from '../Mixins/Translate';
  import Events from '../../enums/event-names';
  import featureNames from '../../enums/feature-names';

  const _ = require('lodash');
  const moment = require('moment');

  /**
   * This is the component showing the gallery filter in the admin site.
   */
  export default {
    name: 'gallery-filter',
    components: {
      'list-filter-item': defineAsyncComponent(() => import('../Filter/ListFilterItem.vue')),
    },
    mixins: [translate],
    data() {
      const filters = {
        districts: [],
        channels: [],
        customers: [],
        products: [],
        campaigns: [],
        chains: [],
        users: [],
        userRoles: [],
        reactions: [],
        dateFrom: '',
        dateTo: '',
      };
      // Computed properties are not available yet in the data method.
      if (this.$store.getters['FeatureToggleStore/areBookmarksEnabled']) {
        filters.bookmarksOnly = false;
      }
      if (this.$store.getters['FeatureToggleStore/areSurveysEnabled']) {
        filters.surveys = [];
      }
      if (this.$store.getters['FeatureToggleStore/isVisitScoreEnabled']) {
        filters.scores = [];
      }
      if (this.$store.getters['FeatureToggleStore/areVisitPlansEnabled']) {
        filters.visitPlans = [];
      }
      const scores = [
        { id: 'red', name: this.x('red') },
        { id: 'yellow', name: this.x('yellow') },
        { id: 'green', name: this.x('green') },
      ];
      return {
        filters,
        featureNames,
        itemRenderer,
        chainRenderer,
        userRenderer,
        customerRenderer,
        scores,
      };
    },
    computed: {
      reactionsList() {
        // obj_texts is not loaded when this component is first rendered, so we need to handle that.
        return this.config.obj_texts
          ? _.union(
            this.config.obj_texts.ReactionTypesExternal,
            this.config.obj_texts.ReactionTypesInternal,
          ) : [];
      },
      ...mapState(['config', 'user']),
      ...mapGetters('FeatureToggleStore', [
        'areVisitPlansEnabled',
        'areProductsVisibleInMenu',
        'areCampaignsVisibleInMenu',
        'areBookmarksEnabled',
        'areSurveysEnabled',
        'isUserRoleFilterEnabled',
        'isReactionsFilterEnabled',
        'isVisitScoreEnabled',
      ]),
    },
    mounted() {
      $(this.$refs.dateFrom).datePicker();
      $(this.$refs.dateTo).datePicker();
      this.registerEventHandlers();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.resetFilter, this.resetFilter);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.resetFilter, this.resetFilter);
      },
      async resetFilter() {
        console.log('resetFilter');
        this.filters.districts = [];
        this.filters.channels = [];
        this.filters.customers = [];
        this.filters.products = [];
        this.filters.campaigns = [];
        this.filters.chains = [];
        this.filters.users = [];
        this.filters.userRoles = [];
        this.filters.reactions = [];
        this.filters.dateFrom = moment().subtract('1', 'week').format('DD-MM-YYYY');
        this.filters.dateTo = '';
        if (this.areBookmarksEnabled) {
          this.filters.bookmarksOnly = false;
        }
        if (this.areSurveysEnabled) {
          this.filters.surveys = [];
        }
        if (this.isVisitScoreEnabled) {
          this.filters.scores = [];
        }
        if (this.areVisitPlansEnabled) {
          this.filters.visitPlans = [];
        }

        $('[name="date-from"]').val(this.filters.dateFrom);
        $('[name="date-to"]').val(this.filters.dateTo);
        await this.doQuery();
      },
      async doQuery() {
        this.filters.dateFrom = $('[name="date-from"]').val();
        this.filters.dateTo = $('[name="date-to"]').val();
        const strObj = JSON.stringify(this.filters);
        this.$store.commit('DownloadStore/setIsLoading', true);
        await this.$nextTick();

        const data = await httpPost('visits/filter', strObj);
        this.$store.commit('DownloadStore/setIsLoading', false);
        this.$store.commit('setGalleryItems', data);
      },
      activeCampaignFilter(allCampaigns) {
        console.log('Filtering active campaigns...');
        const dateFromString = this.$refs.dateFrom.value;
        const dateToString = this.$refs.dateTo.value;
        const dateFrom = !_.isEmpty(dateFromString) ? moment(dateFromString, 'DD-MM-YYYY') : moment.unix(0);
        const dateTo = !_.isEmpty(dateToString) ? moment(dateToString, 'DD-MM-YYYY') : moment.unix(Number.MAX_VALUE);

        return allCampaigns.filter((item) => {
          console.log(item);
          if (!_.isEmpty(item.inactiveDate)) return false;
          if (item.periodFrom && moment(item.periodFrom).isAfter(dateTo)) return false;
          if (item.periodTo && moment(item.periodTo).isBefore(dateFrom)) return false;

          return true;
        });
      },
    },
  };
</script>

<style scoped>
  .favorite-checkbox {
    margin-right: 20px;
  }
</style>
